var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:[
    'download__app--section d-lg-none',
    {
      'top-space': _vm.topSpace,
      'course-page': _vm.isOnCourse
    }
  ]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"download__app--container"},[_c('div',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t("landing_page.download_our_app"))+" ")]),_c('div',{staticClass:"app-images"},[_c('img',{attrs:{"src":_vm.iosIcon,"alt":"ios"}}),_c('img',{attrs:{"src":_vm.androidIcon,"alt":"android"}})])]),_c('div',{staticClass:"coming-btn"},[_vm._v(_vm._s(_vm.$t("general.coming_soon"))+"...")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }