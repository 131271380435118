<template>
  <section
    class="common-section partners-section full__width--section"
    id="partners-section"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="intro-container">
            <div class="heading">
              {{ $t("partner.title") }}
            </div>
            <div class="content">
              {{ $t("partner.content") }}
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="partnersList.length > 0">
        <div class="col-12 partners-container">
          <VueSlickCarousel
            v-bind="sliderOptions"
            class="slick__slider--container variant2 partners-slider"
          >
            <div
              class="spadding-container"
              v-for="partner in partnersList"
              :key="partner.id"
            >
              <PartnerCard
                :partner="partner"
                @cardClick="onCardClick(partner)"
              />
            </div>
          </VueSlickCarousel>
        </div>
      </div>
      <div class="row" v-if="showExploreBtn">
        <div class="col-12 btn-container">
          <LxpButton variant="accent" @click="handleOnClick">
            {{ $t("partner.button.discover_more") }}
          </LxpButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PartnerCard from "./PartnerCard.vue";
import { LxpButton } from "didactica";
export default {
  components: { PartnerCard, LxpButton },
  props: {
    numberOfCards: {
      type: Number,
      default: 3
    },
    showExploreBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      partnersList: [],
      sliderOptions: {
        slidesToShow: 3,
        slidesToScroll: 1,
        draggable: false,
        arrows: false,
        infinite: false,
        autoplay: false,
        responsive: [
          {
            breakpoint: 9999,
            settings: "unslick"
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              centerMode: true,
              centerPadding: this.slickPadding()
            }
          }
        ]
      }
    };
  },
  mounted() {
    this.fetchPartnersList();
    this.slickPadding();
    window.addEventListener("resize", this.slickPadding);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.slickPadding);
  },
  methods: {
    fetchPartnersList() {
      this.$emit("loading-state", true);
      let params = {
        pageNumber: 1,
        pageSize: this.numberOfCards ? this.numberOfCards : 3
      };
      this.$store
        .dispatch("getPartnersList", params)
        .then(response => {
          this.partnersList = response.results;
          this.$emit("loading-state", false);
        })
        .catch(() => {
          this.partnersList = [];
          this.$emit("loading-state", false);
        });
    },
    onCardClick(partner) {
      this.$router.push({
        name: "PartnersDetail",
        params: { org: partner.org_short_name }
      });
    },
    slickPadding() {
      return `${(window.innerWidth - 300) / 2}px`;
    },
    handleOnClick() {
      this.$router.push({
        name: "discovery"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.partners-section {
  background-color: $brand-primary-100;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  font-family: $font-family;
  .intro-container {
    .heading {
      @include h5;
      @include gradient-text;
    }
    .content {
      margin-top: 8px;
      @include course-body;
      font-weight: 400;
      color: $brand-neutral-700;
      max-width: 904px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .partners-container {
    margin-top: 48px;
  }
  .btn-container {
    margin-top: 32px;
    @include flex-horizontal-center;
    .btn {
      height: 54px;
      width: 211px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .partners-slider {
    display: flex;
    justify-content: center;
    .spadding-container {
      &:not(:first-child) {
        margin-left: 24px;
      }
    }
  }
  .partners-section {
    &.discovery-page {
      .partners-slider {
        flex-wrap: wrap;
        max-width: 1024px;
        margin: auto;
        .spadding-container {
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          &:not(:nth-child(-n + 3)) {
            margin-top: 32px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .full__width--section {
    .container {
      max-width: 100%;
      padding-left: 8px;
      padding-right: 8px;
      .row {
        margin-left: -8px;
        margin-right: -8px;
        .col-12 {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
  }
  .partners-section {
    .intro-container {
      .content {
        max-width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
@media only screen and (max-width: 991px) {
  .partners-section {
    .partners-slider {
      .slick-track {
        justify-content: center;
      }
    }
  }
}
</style>
