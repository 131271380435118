var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:[
    'common-section course__slider--section',
    { 'course-page': _vm.isOnCourse },
    { 'd-none': _vm.courseList.length === 0 }
  ]},[(_vm.courseList.length > 0)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"intro-container"},[(_vm.isOnCourse)?_c('i18next',{staticClass:"heading",attrs:{"path":"course.banner_section.title","tag":"div"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("course.banner_section.title_0"))+" ")])]):_c('i18next',{staticClass:"heading",attrs:{"path":"landing_page.course_section.title","tag":"div"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("landing_page.course_section.title_0"))+" ")])]),(!_vm.isOnCourse)?_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t("landing_page.course_section.content"))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 course-container",style:({
          '--slides-length': _vm.courseList.length,
          '--animation-length': Math.floor(_vm.courseList.length / 2)
        })},[_c('div',{class:[
            'course-slider',
            {
              'autoplay-animation': _vm.setAnimation,
              'pause-animation': _vm.pauseAnimation
            }
          ]},_vm._l((_vm.courseList),function(course){return _c('CourseCard',{key:course.id,attrs:{"course":course},on:{"cardClick":_vm.onCardClick,"cardHover":_vm.onCardHover}})}),1)])]),(_vm.showBtn)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 btn-container"},[_c('LxpButton',{attrs:{"variant":"accent"},on:{"click":_vm.handleOnClick}},[_vm._v(" "+_vm._s(_vm.$t("landing_page.course_section.button"))+" ")])],1)]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }