<template>
  <div
    class="course__card--container"
    @click="onCardClick"
    @mouseover="onCardHover(true)"
    @mouseout="onCardHover(false)"
  >
    <div class="course-image">
      <img
        :src="course.image_url ? course.image_url : placeholderImage"
        alt="course"
        @error="handleImageError"
      />
    </div>
    <div class="course__card--inner">
      <div class="course-org">
        {{ course.org }}
      </div>
      <div class="course-title">
        {{ course.title ? course.title : course.course_title }}
      </div>
      <div class="course-footer">
        <div class="course-info course-language text-uppercase">
          <img :src="languageIcon" alt="language" />
          <span>{{ course.language }}</span>
        </div>
        <div class="course-info course-pacing">
          <img :src="pacingIcon" alt="pacing" />
          <span>
            {{ pacingText }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    course: Object
  },
  data() {
    return {
      languageIcon: require("@/assets/images/landing/language.svg"),
      placeholderImage: require("@/assets/images/not-found/course-img-not-found.svg"),
      instructorLedIcon: require("@/assets/images/landing/course/instructor-led.svg"),
      selfPacedIcon: require("@/assets/images/landing/course/self-paced.svg")
    };
  },
  computed: {
    pacingIcon() {
      return this.course.pacing === "self"
        ? this.selfPacedIcon
        : this.instructorLedIcon;
    },
    pacingText() {
      const pacing = this.course.pacing;
      if (pacing === "self" || pacing === "instructor") {
        return this.$t(`data.pacing.${pacing}`);
      } else {
        return pacing;
      }
    }
  },
  methods: {
    onCardClick() {
      this.$emit("cardClick", this.course);
    },
    onCardHover(value) {
      this.$emit("cardHover", value);
    },
    handleImageError(event) {
      event.target.src = this.placeholderImage;
    }
  }
};
</script>

<style lang="scss" scoped>
.course__card--container {
  border-radius: 16px;
  background-color: $brand-neutral-0;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  @include flex-column-start;
  font-family: $font-family;
  cursor: pointer;
  transition: all ease 0.3s;
  width: 230px;
  text-align: left;
  &:hover {
    box-shadow: 6px 14px 64px 10px rgba(0, 129, 255, 0.15);
    transform: scaleY(1.05);
    transition: all ease 0.3s;
  }
  .course-image {
    width: 100%;
    height: 120px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
    transition: all ease 0.3s;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .course__card--inner {
    @include flex-column-start;
    padding: 8px 16px 16px;
    width: 100%;
    background-color: $brand-primary-50;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    flex: 1;
    .course-org {
      @include label-medium(500);
      color: $brand-neutral-400;
    }
    .course-title {
      margin-top: 4px;
      @include body-regular($brand-neutral-900);
      @include line-clamp(3);
      min-height: 72px;
      word-break: break-word;
    }
    .course-footer {
      @include horizontal-space-between;
      margin-top: 16px;
      width: 100%;
      .course-info {
        @include flex-center;
        span {
          margin-left: 4px;
          @include label-medium(500);
          color: $brand-neutral-900;
        }
        &.course-pacing {
          max-width: 120px;
        }
      }
    }
  }
}
</style>
