<template>
  <section
    :class="[
      'download__app--section d-lg-none',
      {
        'top-space': topSpace,
        'course-page': isOnCourse
      }
    ]"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="download__app--container">
            <div class="heading">
              {{ $t("landing_page.download_our_app") }}
            </div>
            <div class="app-images">
              <img :src="iosIcon" alt="ios" />
              <img :src="androidIcon" alt="android" />
            </div>
          </div>
          <div class="coming-btn">{{ $t("general.coming_soon") }}...</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isOnCourse: {
      type: Boolean,
      default: false
    },
    topSpace: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iosIcon: require("@/assets/images/landing/app/ios-logo.svg"),
      androidIcon: require("@/assets/images/landing/app/android-logo.svg")
    };
  }
};
</script>

<style lang="scss" scoped>
.download__app--section {
  padding: 24px 0px 32px;
  font-family: $font-family;
  background-color: $brand-primary-100;
  &.top-space {
    padding-top: 52px;
  }
  &.course-page {
    background-color: $brand-neutral-0;
  }
  .download__app--container {
    @include flex-column-center;
    .heading {
      @include subtitle-regular;
      color: $brand-neutral-900;
    }
    .app-images {
      margin-top: 16px;
      @include flex-center;
      img {
        &:not(:first-child) {
          margin-left: 32px;
          padding-left: 32px;
          border-left: 1px solid $brand-neutral-200;
        }
      }
    }
  }
  .coming-btn {
    padding: 4px 8px;
    background: $linear-gradient-bg;
    @include body-medium;
    color: $brand-neutral-0;
    margin-top: 16px;
    border-radius: 8px;
    display: inline-flex;
  }
}

@media only screen and (max-width: 767px) {
  .download__app--section {
    &.top-space {
      padding-top: 60px;
    }
  }
}
</style>
