<template>
  <div class="partner__card--container">
    <div class="partner-image">
      <img
        :src="partner?.cover_image ? partner?.cover_image : placeholderImage"
        alt="partner"
        @error="handleImageError"
      />
      <div class="partner-logo">
        <img
          :src="partner?.logo ? partner?.logo : placeholderImage"
          alt="partner"
          @error="handleImageError"
        />
      </div>
    </div>
    <div class="partner__card--inner">
      <div class="partner-title">
        {{ partner?.name }} ({{ partner?.org_short_name }})
      </div>
      <div class="partner-content">
        {{ partner?.about?.short_headline }}
      </div>
      <LxpButton variant="outline-primary" @click="onCardClick">
        {{ $t("dashboard.alert.explore") }}
      </LxpButton>
    </div>
  </div>
</template>

<script>
import { LxpButton } from "didactica";
export default {
  components: { LxpButton },
  props: {
    partner: Object
  },
  data() {
    return {
      placeholderImage: require("@/assets/images/not-found/course-img-not-found.svg")
    };
  },
  methods: {
    onCardClick() {
      this.$emit("cardClick", this.partner);
    },
    handleImageError(event) {
      event.target.src = this.placeholderImage;
    }
  }
};
</script>

<style lang="scss" scoped>
.partner__card--container {
  border-radius: 32px;
  background-color: $brand-neutral-0;
  @include flex-column-start;
  font-family: $font-family;
  width: 285px;
  .partner-image {
    width: 100%;
    height: 96px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .partner-logo {
      position: absolute;
      top: 8px;
      left: 16px;
      width: 72px;
      min-width: 72px;
      height: 72px;
      border-radius: 50%;
      background-color: $brand-neutral-0;
      box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);
      @include flex-horizontal-center;
      padding: 12px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .partner__card--inner {
    @include flex-column-start;
    padding: 8px 16px 16px;
    width: 100%;
    background-color: $brand-primary-50;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    text-align: left;
    .partner-title {
      @include body-regular($brand-neutral-900, 500);
      @include line-clamp(2);
      min-height: 48px;
    }
    .partner-content {
      @include body-medium;
      @include line-clamp(1);
      min-height: 21px;
    }
    .btn {
      border-width: 1px;
      width: 100%;
      margin-top: 4px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .partner__card--container {
    box-shadow: 16px 14px 54px 0px rgba(0, 129, 255, 0.15);
  }
}
</style>
