<template>
  <LandingPageLayout>
    <div class="page-loader" v-if="refreshPage">
      <LxpLoader />
    </div>
    <HomeTopBanner />
    <DownloadApp :topSpace="true" />
    <CourseSlider />
    <LearnerBenefits />
    <TopFeatures />
    <Partners />
    <LearnMoreBanner />
    <StayTuned />
  </LandingPageLayout>
</template>

<script>
import userLanguageMixin from "@/core/mixins/userLanguage";

import LandingPageLayout from "../components/Layout/LandingPageLayout.vue";
import HomeTopBanner from "../components/Landing/HomeTopBanner.vue";
import DownloadApp from "../components/Landing/DownloadApp.vue";
import CourseSlider from "../components/Landing/CourseSlider.vue";
import LearnerBenefits from "../components/Landing/LearnerBenefits.vue";
import TopFeatures from "../components/Landing/TopFeatures.vue";
import Partners from "../components/Landing/Partners.vue";
import LearnMoreBanner from "../components/Landing/LearnMoreBanner.vue";
import StayTuned from "../components/Landing/StayTuned.vue";
import { LxpLoader } from "didactica";
export default {
  components: {
    LandingPageLayout,
    HomeTopBanner,
    DownloadApp,
    CourseSlider,
    LearnerBenefits,
    TopFeatures,
    Partners,
    LearnMoreBanner,
    StayTuned,
    LxpLoader
  },
  mixins: [userLanguageMixin],
  data() {
    return {
      refreshPage: false
    };
  },
  mounted() {
    this.refreshPage = true;
    setTimeout(() => {
      this.refreshPage = false;
    }, 500);
    window.scrollTo({ top: 0 });
    this.$emit("update:layout", "main");
    document.querySelector("body").classList.add("hide-overflow");
    this.checkAndSetNewUserLanguage();
  },
  unmounted() {
    document.querySelector("body").classList.remove("hide-overflow");
  }
};
</script>
<style lang="scss" scoped>
.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $brand-neutral-0;
  z-index: 99;
  @include flex-horizontal-center;
}
</style>
